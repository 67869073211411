<div class="d-flex flex-column flex-fill">
    <div class="card-body" *ngIf="loadedBooking.Booking_Type == 'AIR'">
        <h1 class="card-title">
            Air Freight Service
        </h1>
        <div class="d-flex flex-column flex-fill">
            <div class="row mb-2">
                <div class="col-6">
                    <div>
                        <strong>Service Type</strong>:&nbsp; {{loadedConfigs.Air_Service?loadedConfigs.Air_Service:"-"}}
                    </div>
                    <div>
                        <strong>Incoterm</strong>: {{loadedConfigs.Incoterm}}
                    </div>
                </div>
                <div class="col-6">
                    <div>
                        <strong>Commodity Type</strong>:&nbsp;
                        <div class="mb-2"
                            *ngFor="let commodity of filterCommodity(loadedConfigs.Commodity_Type); let last = last">
                            <span *ngIf="!last">{{commodity}},&nbsp;</span>
                            <span *ngIf="last">{{commodity}}</span>
                        </div>
                        <div *ngIf="filterCommodity(loadedConfigs.Commodity_Type).length == 0">&nbsp;None
                            specified
                        </div>
                    </div>
                    <div>
                        <strong>MAWB</strong>: {{loadedConfigs.MAWB ? loadedConfigs.MAWB : "-"}}
                    </div>
                    <div>
                        <strong>HAWB</strong>: {{loadedConfigs.HAWB ? loadedConfigs.HAWB : "-"}}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <div class="form-group p-0">
                        <div><strong>Origin Airport</strong></div>
                        <div>{{getPortName(loadedConfigs.Origin_Airport, loadedConfigs.Origin_Country, 0)}}</div>
                        <div>{{getCountryName(loadedConfigs.Origin_Country)}}</div>
                        <div>{{loadedConfigs.Departure_Date}}</div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group pl-0">
                        <div><strong>Destination Airport</strong></div>
                        <div>{{getPortName(loadedConfigs.Destination_Airport, loadedConfigs.Destination_Country,
                            0)}}</div>
                        <div>{{getCountryName(loadedConfigs.Destination_Country)}}</div>
                        <div>{{loadedConfigs.Arrival_Date}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body" *ngIf="loadedBooking.Booking_Type == 'TRUCKING'">
        <!-- <h1 class="card-title">
            Truck Service
        </h1> -->
        <div class="d-flex flex-row flex-fill">
            <!-- <div class="d-flex flex-column flex-fill">
                <div class="d-flex flex-row"><strong>Truck Type</strong>:&nbsp;
                    {{loadedConfigs.Truck_Type?loadedConfigs.Truck_Type:"-"}}
                </div>
                <div class="d-flex flex-row"><strong>Departure Date</strong>:&nbsp; {{loadedConfigs.Departure_Date}}
                </div>
            </div> -->
            <!-- <div class="d-flex flex-column flex-fill">
                <div class="d-flex flex-row"><strong>Commodity Type</strong>:&nbsp;
                    <div *ngFor="let commodity of filterCommodity(loadedConfigs.Commodity_Type); let last = last">
                        <span *ngIf="!last">{{commodity}},&nbsp;</span>
                        <span *ngIf="last">{{commodity}}</span>
                    </div>
                    <div *ngIf="filterCommodity(loadedConfigs.Commodity_Type).length == 0">&nbsp;None
                        specified
                    </div>
                </div>
                <div class="d-flex flex-row"><strong>Arrival Date</strong>:&nbsp; {{loadedConfigs.Arrival_Date}}
                </div>
            </div> -->
        </div>
    </div>
    <div class="card-body" *ngIf="loadedBooking.Booking_Type == 'SEA'">
        <h1 class="card-title">
            Sea Freight Service
        </h1>
        <div class="row mb-2">
            <div class='col-6'>
                <strong>Incoterm</strong>: {{loadedConfigs.Incoterm}}
            </div>
            <div class='col-6'>
                <div class='col-6'>
                    &nbsp;
                </div>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-6">
                <div>
                    <strong>Origin Port</strong>
                </div>
                <div>{{loadedConfigs.Origin_Port}}</div>
                <div>{{getCountryName(loadedConfigs.Origin_Country)}}</div>
                <div>{{loadedConfigs.Departure_Date}}</div>
            </div>
            <div class="col-6">
                <div class="col-6">
                    <div>
                        <strong>Destination Port</strong>
                    </div>
                    <div>{{loadedConfigs.Destination_Port}}</div>
                    <div>{{getCountryName(loadedConfigs.Destination_Country)}}</div>
                    <div>{{loadedConfigs.Arrival_Date}}</div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="mb-2">
                    <strong>Container Load</strong>
                </div>
                <table class="table table-bordered">
                    <tr>
                        <td>
                            Container Type
                        </td>
                        <td>
                            Quantity
                        </td>
                        <td>
                            Size
                        </td>
                        <td>
                            FCL/LCL
                        </td>
                    </tr>
                    <tr *ngFor="let container of loadedConfigs.containerLoad">
                        <td>
                            {{container.containerType}}
                        </td>
                        <td>
                            {{container.quantity}}
                        </td>
                        <td>
                            {{container.containerSize}}
                        </td>
                        <td>
                            {{container.containerLoad}}
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
    <div *ngIf="loadedBooking.Booking_Type == 'GRN' || loadedBooking.Booking_Type == 'GIN'">
        <h4 *ngIf="loadedBooking.Booking_Type == 'GRN'">
            Inbound Booking
        </h4>
        <h4 *ngIf="loadedBooking.Booking_Type == 'GIN'">
            Outbound Booking
        </h4>
        <div class="d-flex flex-row flex-fill">
            <table class="table table-bordered">
                <tr>
                    <td width="30%">
                        <strong>Forwarder Name</strong>
                    </td>
                    <td>
                        {{loadedConfigs.serviceForm.thirdPartyForwarder?loadedConfigs.serviceForm.thirdPartyForwarder:"-"}}                        
                    </td>
                </tr>
                <tr>
                    <td width="30%">
                        <strong>Hand/Takeover Date</strong>
                    </td>
                    <td>
                        {{loadedConfigs.serviceForm.thirdPartyDate?loadedConfigs.serviceForm.thirdPartyDate:"-"}}                        
                    </td>
                </tr>
                <tr>
                    <td width="30%">
                        <strong>Forwarder Contact No.</strong>
                    </td>
                    <td>
                        {{loadedConfigs.serviceForm.thirdPartyContact?loadedConfigs.serviceForm.thirdPartyContact:"-"}}                        
                    </td>
                </tr>
                <tr>
                    <td width="30%">
                        <strong>Master Airway Bill No.</strong>
                    </td>
                    <td>
                        {{loadedConfigs.serviceForm.Tracking_MAWB?loadedConfigs.serviceForm.Tracking_MAWB:"-"}}                        
                    </td>
                </tr>
                <tr>
                    <td width="30%">
                        <strong>House Airway Bill No.</strong>
                    </td>
                    <td>
                        {{loadedConfigs.serviceForm.Tracking_HAWB?loadedConfigs.serviceForm.Tracking_HAWB:"-"}}                        
                    </td>
                </tr>
                <tr>
                    <td width="30%">
                        <strong>Bill of Lading</strong>
                    </td>
                    <td>
                        {{loadedConfigs.serviceForm.Tracking_BL?loadedConfigs.serviceForm.Tracking_BL:"-"}}                        
                    </td>
                </tr>
                <tr>
                    <td width="30%">
                        <strong>Shipment Details</strong>
                    </td>
                    <td>
                        {{loadedConfigs.serviceForm.Tracking_Details?loadedConfigs.serviceForm.Tracking_Details:"-"}}                        
                    </td>
                </tr>
                <tr>
                    <td width="30%">
                        <strong>Reference 1</strong>
                    </td>
                    <td>
                        {{loadedConfigs.serviceForm.reference1?loadedConfigs.serviceForm.reference1:"-"}}                        
                    </td>
                </tr>
                <tr>
                    <td width="30%">
                        <strong>Reference 2</strong>
                    </td>
                    <td>
                        {{loadedConfigs.serviceForm.reference2?loadedConfigs.serviceForm.reference2:"-"}}                        
                    </td>
                </tr>
                <!-- Add Permit No. -->
                <tr>
                    <td width="30%">
                        <strong>Permit No.</strong>
                    </td>
                    <td>
                        {{loadedBooking.Items[0].Permit_No ? loadedBooking.Items[0].Permit_No:"-"}}
                    </td>
                </tr>
            </table>
        </div>
    </div>
</div>