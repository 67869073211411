import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Sanitizer } from '@angular/core';
import { StaticDataService } from './static-data.service';

@Injectable({
  providedIn: 'root'
})
export class FileDownloadService {
  constructor (private http: HttpClient, private staticDataService: StaticDataService) { }

  // //add promise
  // downloadFile = function(url, filename){
  //   return new Promise((resolve, reject)=>{
  //     let headers = new HttpHeaders({});
  //     let downloaded = false;

  //     this.http.get(url, { headers: headers, responseType: 'blob' }).subscribe(response => {

  //       console.log(response);

  //       let mime = this.resolveMime(this.staticDataService.getExtension(filename));

  //       const blob = new Blob([response], { type: mime });

  //       let downloadLink = document.createElement('a');
  //       let blobURL = window.URL.createObjectURL(response);
  //       console.log(blobURL);
  //       downloadLink.href = blobURL;
  //       downloadLink.download = filename;
  //       downloadLink.click();

  //       downloaded = true;
  //       resolve(true);
  //     },
  //     error =>{
  //       resolve(false);
  //     });
  //   });
  // };

  downloadFile(url, filename) {
    let headers = new HttpHeaders({});
    this.http.get(url, { headers: headers, responseType: 'text' }).subscribe(response => {

      const downloadedBlob = atob(response);

      const byteNumbers = new Array(downloadedBlob.length);
      for(let i = 0; i < downloadedBlob.length; i++) {
        byteNumbers[i] = downloadedBlob.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      let mime = this.resolveMime(this.staticDataService.getExtension(filename));
      const blob = new Blob([byteArray], { type: mime });

      let downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download = filename;
      downloadLink.click();
    });
  }

  // downloadFile(url, filename) {
  //   let headers = new HttpHeaders({});
  //   let downloaded = false;

  //   this.http.get(url, { headers: headers, responseType: 'blob' }).subscribe(response => {

  //     console.log(response);

  //     // const downloadedBlob = atob(response);

  //     // const byteNumbers = new Array(downloadedBlob.length);
  //     // for(let i = 0; i < downloadedBlob.length; i++) {
  //     //    //Get unicode of the letter
  //     //   byteNumbers[i] = downloadedBlob.charCodeAt(i);
  //     // }
  //     // const byteArray = new Uint8Array(byteNumbers);

  //     let mime = this.resolveMime(this.staticDataService.getExtension(filename));

  //     const blob = new Blob([response], { type: mime });

  //     let downloadLink = document.createElement('a');
  //     let blobURL = window.URL.createObjectURL(response);
  //     console.log(blobURL);
  //     downloadLink.href = blobURL;
  //     downloadLink.download = filename;
  //     downloadLink.click();

  //     downloaded = true;
  //     // const blob = new Blob([byteArray], { type: mime });

  //     // let downloadLink = document.createElement('a');
  //     // downloadLink.href = window.URL.createObjectURL(blob);
  //     // downloadLink.download = filename;
  //     // downloadLink.click();
  //   });

  //   return downloaded;
  // }

  downloadPDF(url, filename) {

    let headers = new HttpHeaders({});
    headers = headers.set('Accept', 'application/pdf');

    this.http.get(url, { headers: headers, responseType: 'blob' }).subscribe(response => {

      const downloadedBlob = response;
      let downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(downloadedBlob);
      downloadLink.download = filename + ".pdf";
      downloadLink.click();
    });
  }

  //Used for "Booking" -> "Administer" -> "Generate DO" to download DO
  downloadDO(url, DOData, filename) {

    // // Previous code
    // let headers = new HttpHeaders({});
    // //headers = headers.set('Accept', 'application/pdf');
    // headers = headers.set('Accept', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');

    // this.http.post(url, DOData,{ headers: headers, responseType: 'blob' }).subscribe(response => {

    //   console.log(response);
    //   console.log("enter here");

    //   const downloadedBlob = response;
    //   let downloadLink = document.createElement('a');
    //   downloadLink.href = window.URL.createObjectURL(downloadedBlob);
    //   downloadLink.download = filename;
    //   downloadLink.click();
    // });
    // // Previous Code end

    let headers = new HttpHeaders({});

    this.http.post(url, DOData,{ headers: headers, responseType: 'text' }).subscribe(response => {
    
      const downloadedBlob = atob(response);

      const byteNumbers = new Array(downloadedBlob.length);
      for(let i = 0; i < downloadedBlob.length; i++) {
        byteNumbers[i] = downloadedBlob.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      let mime = this.resolveMime(this.staticDataService.getExtension(filename));
      const blob = new Blob([byteArray], { type: mime });

      let downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download = filename;
      downloadLink.click();

    });
  }

  resolveMime(ext) {
    // resolve ext to mime type lel
    return this.staticDataService.getMime(ext);
  }
}

