import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { OverlayManagerService } from 'src/app/overlay-manager.service';


@Component({
  selector: 'app-product-summary-data',
  templateUrl: './product-summary-data.component.html',
  styleUrls: ['./product-summary-data.component.scss']
})
export class ProductSummaryDataComponent implements OnInit {

  ROUTE_CUSTOMER_PRODUCT_PROFILE_DATA = environment.api_location+"/products/profile/";
  ROUTE_CUSTOMER_PRODUCT_AVAILABLE_DATA;

  productID;
  availableData;
  filterLocData = "ALL";
  filterData = "";
  filterTerm;

  loc: {value: string}[] = [{value: "ALL"}];

  //for sorting- default desc
  sortType = "DSC";

  constructor(private http: HttpClient, private overlayManager: OverlayManagerService) { }

  ngOnInit(): void {
    this.productID = this.productSummaryData.ID;

    this.ROUTE_CUSTOMER_PRODUCT_PROFILE_DATA += this.productID;
    this.ROUTE_CUSTOMER_PRODUCT_AVAILABLE_DATA = this.ROUTE_CUSTOMER_PRODUCT_PROFILE_DATA+"/available-summary";

    this.http.get(this.ROUTE_CUSTOMER_PRODUCT_AVAILABLE_DATA).subscribe(response => {
      this.availableData = response;

      // console.log(this.availableData.length);
      // console.log(this.productID);

      (this.availableData as any[]).sort((a,b) => {
        return b.Age - a.Age;
      });

      for(let val of this.availableData){
        let storage = val.Bin_Location;
        let location = storage.split("-",1)[0];
  
        if(!this.loc.some(el => el.value === location)){
          this.loc.push({value: location});
        }

        val.filterTerm = "REF:"+val.Ref+"|SN:"+val.Serial_No+"|LOCATION:"+val.Bin_Location;
        val.filterTerm = val.filterTerm.toUpperCase();
      }
    });
  }

  get productSummaryData(){
    return this.overlayManager.productDataSummary;
  }

  sortData(key){
    console.log(key);

    if (this.sortType === "DSC"){
      this.sortType = "ASC";

      // (this.availableData as any[]).sort((a,b) => {
      //   return a.Age - b.Age;
      // });
    }else {
      this.sortType = "DSC";

      // (this.availableData as any[]).sort((a,b) => {
      //   return b.Age - a.Age;
      // });
    }
  }

  closePanel(){
    this.overlayManager.closePanel();
  }

}
