<div class="d-flex flex-row flex-fill justify-content-center overflow-hidden pt-3" *ngIf="isLoading">
    <div class="d-flex flex-column justify-content-center pt-3">
        <div class="d-flex flex-row justify-content-center">
            <img class='d-flex mb-2' width="158px" src="./assets/image_warehouse shelve.png">
        </div>
        <div class="d-flex flex-column justify-content-center mb-3">
            <strong>Please wait a moment while the content loads</strong>
        </div>
        <div class="d-flex flex-row justify-content-center">
            <div class="progress">
                <div class="indeterminate"></div>
            </div>
        </div>
    </div>
</div>
<div class="d-flex flex-fill overflow-hidden" *ngIf="!isLoading">
    <div class="d-flex flex-fill flex-column justify-content-center align-content-center"
        *ngIf="!bookings || (bookings.length == 0 && (bookingFilterType == BOOKING_FILTER_TYPE_ALL) && (completionFilter == BOOKING_FILTER_TYPE_ALL))">
        <div class="d-flex flex-fill flex-row justify-content-center">
            <div class="d-flex flex-column justify-content-center">
                <div class="d-flex flex-row justify-content-center">
                    <img class='d-flex mb-2' width="158px" src="./assets/image_warehouse_unavailable.svg">
                </div>
                <div class="d-flex flex-row justify-content-center mb-3">
                    <strong>We are unable to find any bookings under this account</strong>
                </div>
                <div class="d-flex flex-row justify-content-center booking-btn">
                    <a (click)="openBookingDialog()">Click here to create a booking</a>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex flex-row flex-fill overflow-hidden"
        *ngIf="!(bookings.length == 0 && (bookingFilterType == BOOKING_FILTER_TYPE_ALL) && (completionFilter == BOOKING_FILTER_TYPE_ALL))">
        <div class="directory-container d-flex flex-column flex-fill border-right">
            <div class="d-flex flex-column pl-3 pt-3 pb-2" id="scroller-container">
                <div class="d-flex flex-column">
                    <div class="d-flex flex-row align-content-center justify-content-center">
                        <div class="d-flex flex-fill pb-2 pr-2">
                            <div class="input-group">
                                <div class="input-group-prepend icon-left pt-2 pl-2 pr-2">
                                    <i class="fas fa-search muted"></i>
                                </div>
                                <input class="form-control form-control-right" [(ngModel)]="bookingFilterTerm"
                                    placeholder="Search booking ref no.">
                            </div>
                        </div>
                        <div class="d-flex flex-grow-0 pr-2">
                            <select class="form-control"
                                [(ngModel)]="completionFilter">
                                <option [value]="BOOKING_FILTER_TYPE_ALL">All</option>
                                <option [value]="COMPLETION_FILTER_TYPE_PENDING">Pending TIC Action</option>
                                <option [value]="COMPLETION_FILTER_TYPE_ONGOING">Processed</option>
                                <option [value]="COMPLETION_FILTER_TYPE_COMPLETED">Completed</option>
                            </select>
                        </div>
                        <div class="d-flex flex-grow-0 pr-2">
                            <select class="form-control"
                                [(ngModel)]="bookingFilterType">
                                <option [value]="BOOKING_FILTER_TYPE_ALL">All</option>
                                <option [value]="BOOKING_FILTER_TYPE_INBOUND">Inbound</option>
                                <option [value]="BOOKING_FILTER_TYPE_OUTBOUND">Outbound</option>
                                <option [value]="BOOKING_FILTER_TYPE_TRUCKING">Trucking</option>
                                <option [value]="BOOKING_FILTER_TYPE_AIR">Air Freight</option>
                                <!-- <option [value]="BOOKING_FILTER_TYPE_SEA">Sea Freight</option> -->
                            </select>
                        </div>
                    </div>
                </div>
                <div id="scroller" class="pr-2">
                    <div class="d-flex flex-fill overflow-hidden">
                        <div class="d-flex flex-column flex-fill scroller">
                            <div class="booking-item pt-2 pb-2 pl-2 pr-2 mb-2 border-bottom"
                                *ngFor="let booking of bookings | adminBookingFilter:bookingFilterTerm:completionFilter:bookingFilterType"
                                (click)="selectBooking(booking)"
                                [ngClass]="{'booking-item-active':selectedBooking.ID == booking.ID}">
                                <div class="d-flex flex-row mb-2">
                                    <div class="d-flex booking-item-title">
                                        <strong>{{booking.Ref_No}}</strong>
                                    </div>
                                    <div class="d-flex flex-fill">
                                        &nbsp;
                                    </div>
                                    <div class="d-flex">
                                        {{booking.Pick_Up_Date}}
                                    </div>
                                </div>
                                <div class="d-flex flex-row mb-2">
                                    <div class="d-flex">
                                        <div class="d-flex flex-fill badge badge-secondary"
                                            *ngIf="booking.Booking_Type == BOOKING_FILTER_TYPE_AIR">
                                            <i class='fas fa-plane-departure mr-1'></i> Air Freight
                                        </div>
                                        <div class="d-flex flex-fill badge badge-secondary"
                                            *ngIf="booking.Booking_Type == BOOKING_FILTER_TYPE_SEA">
                                            <i class='fas fa-ship mr-1'></i> Sea Freight
                                        </div>
                                        <div class="d-flex flex-fill badge badge-secondary"
                                            *ngIf="booking.Booking_Type == BOOKING_FILTER_TYPE_TRUCKING">
                                            <i class='fas fa-shipping-fast mr-1'></i> Trucking
                                        </div>
                                        <div class="d-flex flex-fill badge badge-secondary"
                                            *ngIf="booking.Booking_Type == BOOKING_FILTER_TYPE_INBOUND">
                                            <i class='fas fa-sign-in-alt mr-1'></i> Inbound
                                        </div>
                                        <div class="d-flex flex-fill badge badge-secondary"
                                            *ngIf="booking.Booking_Type == BOOKING_FILTER_TYPE_OUTBOUND">
                                            <i class='fas fa-sign-out-alt mr-1'></i> Outbound
                                        </div>
                                    </div>
                                    <div class="d-flex flex-fill">
                                        &nbsp;
                                    </div>
                                    <div class="d-flex mr-1">
                                        <span class="badge badge-primary" *ngIf="booking.Booking_Type == 'GIN'">{{booking.GIN_Permit}}</span>
                                        <span class="badge badge-primary" *ngIf="booking.Booking_Type == 'GRN'">{{booking.GRN_Permit}}</span>
                                    </div>
                                    <div class="d-flex" *ngIf="booking.status==COMPLETION_FILTER_TYPE_PENDING">
                                        <span class="badge badge-secondary">Pending TIC Action</span>
                                    </div>
                                    <div class="d-flex" *ngIf="booking.status==COMPLETION_FILTER_TYPE_ONGOING">
                                        <span class="badge badge-warning">Ongoing</span>
                                    </div>
                                    <div class="d-flex" *ngIf="booking.status==COMPLETION_FILTER_TYPE_COMPLETED">
                                        <span class="badge badge-success">Completed</span>
                                    </div>
                                </div>
                                <div class="d-flex flex-column">
                                    <div class="d-flex flex-row">
                                        <div class="d-flex flex-column">
                                            <div
                                                class="colored-header d-flex pl-1 pr-1 bg-primary text-white mr-2 text-center mb-1">
                                                1
                                            </div>
                                            <div class="d-flex flex-fill flex-row"
                                                *ngIf="booking && booking.Pick_Up_Address">
                                                <div class="ml-2 timeline-bridge"></div>
                                            </div>
                                        </div>
                                        <div class="d-flex flex-row flex-fill">
                                            <div class="d-flex flex-column justify-content-center text-pre-wrap">
                                                {{booking.Pick_Up_Address}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex flex-row">
                                        <div class="d-flex flex-row">
                                            <div class="ml-2 timeline-bridge"></div>
                                        </div>
                                        <div class="d-flex flex-fill flex-row">
                                            &nbsp;
                                        </div>
                                    </div>
                                    <div class="d-flex flex-row mt-1 mb-2">
                                        <div class="d-flex flex-column">
                                            <div
                                                class="colored-header pl-1 pr-1 bg-warning text-white mr-2 text-center">
                                                2
                                            </div>
                                        </div>
                                        <div class="d-flex flex-row flex-fill ">
                                            <div class="d-flex flex-column justify-content-center text-pre-wrap">
                                                {{booking.Delivery_Address}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex flex-column flex-fill">
            <div class="d-flex flex-row booking-tab-bar ml-3 mr-3">
                <div class="booking-tab" [ngClass]="{'booking-tab-current': currentTab == BOOKING_DETAILS}"
                    (click)="selectTab(BOOKING_DETAILS)">Items</div>
                <div class="booking-tab" [ngClass]="{'booking-tab-current': currentTab == BOOKING_SUMMARY}"
                    (click)="selectTab(BOOKING_SUMMARY)">Booking Summary
                </div>
                <div class="booking-tab" [ngClass]="{'booking-tab-current': currentTab == BOOKING_DOCUMENTS}"
                    (click)="selectTab(BOOKING_DOCUMENTS)">Documents
                </div>
                <div class="booking-tab"  [ngClass]="{'booking-tab-current': currentTab == BOOKING_ADMIN}"
                    (click)="selectTab(BOOKING_ADMIN)">Administer
                </div>
                <div class="d-flex flex-row-reverse flex-fill">
                    <!-- <div class="d-flex align-items-center justify-content-center booking-edit-button"><i
                            class="fas fa-edit ml-2"></i>
                    </div> -->
                    <div class="d-flex align-items-center justify-content-center"
                        *ngIf="selectedBooking.Processed_DateTime == null && selectedBooking.Closed_DateTime == null">
                        <span class="badge badge-secondary">
                            Pending Action
                        </span>
                        <!-- <span class="badge badge-info ml-4 px-2 clickable-text">
                            <i class="fas fa-edit"></i>
                            Edit
                        </span> -->
                    </div>
                    <div class="d-flex align-items-center justify-content-center"
                        *ngIf="selectedBooking.Processed_DateTime != null && selectedBooking.Closed_DateTime == null">
                        <span class="badge bg-warning">
                            Processed
                        </span>
                    </div>
                    <div class="d-flex align-items-center justify-content-center"
                        *ngIf="selectedBooking.Close_DateTime != null">
                        <span class="badge bg-success">
                            Completed
                        </span>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-column flex-fill overflow-auto mr-3"
                *ngIf="loadedBooking && !loadingBooking && currentTab == BOOKING_SUMMARY">
                <div class="container-fluid">
                    <div class="row pl-3 pt-3">
                        <div class="col-6 p-0 d-flex">
                            <div class="d-flex flex-fill card mr-2">
                                <div class="card-body d-flex flex-fill flex-column">
                                    <div class="d-flex flex-row mb-2">
                                        <div class="d-flex flex-column">
                                            <div
                                                class="colored-header d-flex pl-1 pr-1 bg-primary text-white mr-2 text-center">
                                                1
                                            </div>
                                            <div class="d-flex flex-fill flex-row">
                                                <div class="ml-2 timeline-bridge"></div>
                                            </div>
                                        </div>
                                        <div class="d-flex flex-column text-primary">
                                            Origin
                                        </div>
                                        <!-- <div class="d-flex flex-column flex-fill text-right">
                                            {{loadedBooking.Pick_Up_Date}} {{loadedBooking.Pick_Up_Time}}:00
                                        </div> -->
                                    </div>
                                    <div class="d-flex flex-fill flex-column">
                                        <div class="d-flex text-pre-wrap">
                                            <strong>Address</strong>: {{loadedBooking.Pick_Up_Address}}
                                        </div>
                                        <div class="d-flex text-pre-wrap">
                                            <strong>Country</strong>: {{loadedBooking.Country_Of_Origin}}
                                        </div>
                                        <div class="d-flex text-pre-wrap">
                                            <strong>Contact Person</strong>: {{loadedBooking.Sender_Name}}
                                        </div>
                                        <div class="d-flex text-pre-wrap">
                                            <strong>Contact No</strong>: {{loadedBooking.Sender_Contact}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 p-0 d-flex">
                            <div class="d-flex flex-fill card ml-2">
                                <div class="card-body d-flex flex-fill flex-column">
                                    <div class="d-flex flex-row mb-2">
                                        <div class="d-flex flex-column">
                                            <div
                                                class="colored-header d-flex pl-1 pr-1 bg-warning text-white mr-2 text-center">
                                                2
                                            </div>
                                            <div class="d-flex flex-fill flex-row">
                                                <div class="ml-2 timeline-bridge"></div>
                                            </div>
                                        </div>
                                        <div class="d-flex flex-column text-warning">
                                            Destination
                                        </div>
                                        <!-- <div class="d-flex flex-column flex-fill text-right">
                                            {{loadedBooking.Delivery_Date}} {{loadedBooking.Delivery_Time}}:00
                                        </div> -->
                                    </div>
                                    <div class="d-flex flex-fill flex-column">
                                        <div class="d-flex text-pre-wrap">
                                            <strong>Address</strong>: {{loadedBooking.Delivery_Address}}
                                        </div>
                                        <div class="d-flex text-pre-wrap">
                                            <strong>Country</strong>: {{loadedBooking.Country_Of_Destination}}
                                        </div>
                                        <div class="d-flex text-pre-wrap">
                                            <strong>Contact Person</strong>: {{loadedBooking.Recipient_Name}}
                                        </div>
                                        <div class="d-flex text-pre-wrap">
                                            <strong>Contact No</strong>: {{loadedBooking.Recipient_Contact}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-fill row pl-3 pt-3">
                        <app-booking-service-details [loadedBooking]="loadedBooking"></app-booking-service-details>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-column flex-fill overflow-hidden" *ngIf="currentTab == BOOKING_DETAILS">
                <div class="d-flex flex-fill flex-column overflow-auto pb-3">
                    <div class="d-flex table-responsive pl-3 pt-3 pr-3"
                        *ngIf="loadedBooking&&loadedBooking.Items.length > 0">
                        <table class='table table-bordered table-striped'>
                            <thead>
                                <tr>
                                    <th scope="col" class="text-center">
                                        Description
                                    </th>
                                    <th scope="col" class="text-center">
                                        SKU
                                    </th>
                                    <th scope="col" class="text-center">
                                        Serial No.
                                    </th>
                                    <th scope="col" class="text-center">
                                        Part No.
                                    </th>
                                    <th scope="col" class="text-center">
                                        Quantity
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of loadedBooking.Items">
                                    <td (click)="showItemDetails(item)">
                                        {{item.Description}}
                                    </td>
                                    <td (click)="showItemDetails(item)">
                                        {{item.SKU?item.SKU:"-"}}
                                    </td>
                                    <td class="text-center" (click)="showItemDetails(item)">
                                        {{item.Serial_No?item.Serial_No:"-"}}
                                    </td>
                                    <td class="text-center" (click)="showItemDetails(item)">
                                        {{item.Part_No?item.Part_No:"-"}}
                                    </td>
                                    <td class="text-center" (click)="showItemDetails(item)">
                                        {{item.Quantity?item.Quantity:"-"}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-column flex-fill overflow-hidden" *ngIf="currentTab == BOOKING_DOCUMENTS">
                <div class="container-fluid">
                    <div class="overflow-auto p-3">
                        <div class="row d-flex flex-row mb-2 document p-2" *ngFor="let doc of loadedBooking.Documents"
                            (click)="downloadFile(doc)">
                            <div class="d-flex flex-row flex-fill">
                                <i class="far fa-file-pdf fa-4x text-danger" *ngIf="doc.Extension == 'pdf'"></i>
                                <i class="far fa-file-image fa-4x text-primary"
                                    *ngIf="doc.Extension == 'jpg' || doc.Extension == 'jpeg'|| doc.Extension == 'png' || doc.Extension == 'webp'"></i>
                                <i class="far fa-file-excel fa-4x text-success" *ngIf="doc.Extension == 'xlsx'"></i>
                                <i class="far fa-file-powerpoint fa-4x text-warning"
                                    *ngIf="doc.Extension == 'ppt' || doc.Extension == 'pptx'"></i>
                                <div class="d-flex flex-column flex-fill justify-content-center pl-2">
                                    <div class="document-title">{{doc.Original_File_Name}}</div>
                                    <div class="document-description">{{doc.Created_DateTime}}</div>
                                </div>
                                <div class="d-flex flex-row-reverse flex-fill justify-content-start align-items-center">
                                    <i class="document-delete fas fa-times p-3"
                                        (click)="undoDelete(doc);$event.stopPropagation()"
                                        *ngIf="doc.showDeleteFileView"></i>
                                    <i class="document-delete fas fa-check p-3"
                                        (click)="deleteFile(doc);$event.stopPropagation()"
                                        *ngIf="doc.showDeleteFileView"></i>
                                    <i class="document-delete fas fa-trash p-3"
                                        (click)="openDeleteFile(doc);$event.stopPropagation()"
                                        *ngIf="!doc.showDeleteFileView"></i>
                                </div>
                            </div>
                        </div>
                        <div class="row d-flex flex-row">
                            <div class="d-flex flex-fill file-drag-area" (click)="fileInput.click()" appDragDrop
                                (onFileDropped)="addFile($event)">
                                <div class="d-flex flex-fill flex-column justify-content-center">
                                    <div class="d-flex flex-row justify-content-center">
                                        <div class="d-flex flex-row">
                                            <div class='d-flex flex-column justify-content-center mr-3'>
                                                <i class="fas fa-file-alt fa-2x"> </i>
                                            </div>
                                            <div class="d-flex flex column justify-content-center drag-drop-text">
                                                <strong><span class="text-primary">Click Here</span> to upload
                                                    files<br>or
                                                    drag and drop files here</strong>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <input hidden type="file" #fileInput (change)="addFile($event.target.files)">
                            </div>
                        </div>
                        <div class="row d-flex flex-row mt-3">
                            <div class="d-flex flex-grow-0 mr-3 p-2 mb-2 upload-item"
                                *ngFor="let file of filesToUpload">
                                <div class="d-flex flex-row flex-fill">
                                    <div class="d-flex flex-column justify-content-center mr-2">
                                        <i [ngClass]="getType(file.name)" class='fa-2x'></i>
                                    </div>
                                    <div class="d-flex flex-column">
                                        <div><strong>{{file.name}}</strong></div>
                                        <div>{{getSize(file.size)}}</div>
                                    </div>
                                    <div class="d-flex flex-column justify-content-center pl-2 pr-2">
                                        <div class='d-flex flex-grow-0' (click)="removeFile(file)">
                                            <i class="fas fa-times close-btn"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row d-flex flex-row mt-2 align-items-center justify-content-center"
                            *ngIf="filesToUpload.length > 0">
                            <button class="btn btn-success" (click)="uploadFiles()">Upload files</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-column flex-fill overflow-auto mr-3 p-3" *ngIf="loadedBooking && !loadingBooking && currentTab == BOOKING_ADMIN">
                <div class="d-flex flex-column card mb-3">
                    <div class="d-flex flex-row border bottom p-1 card-header">
                        <div class="mr-3"><strong>Task: Verify and Confirm the Booking</strong></div><div *ngIf="isProcessed()"><i class='fa-solid fa-circle-check text-success'></i></div>
                    </div>
                    <div *ngIf="!isProcessed()">
                        <div class="d-flex flex-column p-1">
                            <p>Please verify the details of the booking. <br>Once a booking has been confirmed, it cannot be changed</p> 
                        </div>
                        <div class="d-flex flex-row pl-1 pr-3 pb-2">
                            <div class="pr-3">
                                <button class="btn btn-warning" (click)="deleteBookingStep1()" *ngIf="!deletingBooking">Delete Booking</button>
                                <button class="btn btn-warning" (click)="cancelDelete()" *ngIf="deletingBooking">Cancel Delete</button>
                            </div>
                            <div *ngIf="deletingBooking">
                                <button class="btn btn-danger" (click)="deleteBookingStep2()" *ngIf="deletingBooking">Delete the booking</button>
                            </div>
                            <div *ngIf="!deletingBooking">
                                <button class="btn btn-primary" (click)="processBooking()">Confirm Booking</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="GRN_Task != null">
                    Form 2 - GRN Details
                </div>
                <div class="d-flex flex-column card mb-3" *ngIf="GIN_Task != null && GIN_Task.ID != null">
                    <div class="d-flex flex-row border bottom p-1 card-header">
                        <div class="mr-3"><strong>Task: GIN Details</strong></div>
                    </div>
                    <div class="d-flex flex-row p-3">
                        <div class="d-flex flex-fill flex-column mr-3">  
                            <div>
                                <strong>Permit #</strong>
                            </div>
                            <input class="form-control" [(ngModel)]="GIN_Task.Permit_No">
                        </div>
                        <div class="d-flex flex-fill flex-column mr-3">  
                            <div>
                                <strong>Expected Execution Date</strong>
                            </div>
                            <input type="date" class="form-control" [(ngModel)]="GIN_Task.Expected_Date">
                        </div>
                        <div class="d-flex flex-fill flex-column">  
                            <div>
                                <strong>Completed Date Time</strong>
                            </div>
                            <input type="datetime-local" class="form-control" [(ngModel)]="GIN_Task.Completed_DateTime">
                        </div>
                    </div>
                    <div class="d-flex flex-row pl-3 pr-3">
                        <div>
                            <h4>Items</h4>
                        </div>
                    </div>
                    <div class="d-flex flex-column border-bottom mb-3" *ngFor="let GIN_Item of GIN_Task.Items; let odd = odd" [ngClass]="{'bg-active': odd}">
                        <div class="d-flex flex-row pl-3 pr-3 mb-2">
                            <div class="mr-3">
                                <strong>
                                    Serial No.
                                </strong><br>
                                {{GIN_Item.Serial_No}}
                            </div>
                            <div>
                                <strong>
                                    Description
                                </strong><br>
                                {{GIN_Item.Description}}
                            </div>
                        </div>
                        <div class="d-flex flex-row pl-3 pr-3 pb-3">
                            <div class="d-flex flex-column flex-fill mr-3">
                                <div>
                                    <strong>
                                        Actual Quantity
                                    </strong>
                                </div>
                                <input class="form-control" [(ngModel)]="GIN_Item.Actual_Quantity" type="number" min="0" disabled>
                            </div>
                            <div class="d-flex flex-column flex-fill mr-3">
                                <div>
                                    <strong>
                                        Expected Quantity
                                    </strong>
                                </div>
                                <input class="form-control" [(ngModel)]="GIN_Item.Quantity" type="number" min="0" disabled>
                            </div>
                            <div class="d-flex flex-column flex-fill">
                                <div>
                                    <strong>
                                        Denomination
                                    </strong>
                                </div>
                                <input class="form-control" [(ngModel)]="GIN_Item.Denomination" type="number" min="0" disabled>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-row" >
                        <div class="d-flex flex-fill">
                            &nbsp;
                        </div>
                        <!-- Amv6 -->
                        <div class="pr-3" *ngIf="isProcessed() && !isClosed()">
                            <button class="btn btn-warning" (click)="deleteGIN()" [disabled]="triggerBookingDelete">Delete Booking</button>
                        </div>
                        <!-- Amv6 -end -->
                        <!-- Add Hyperlink to generate DO -->
                        <div (click)="getDO()" class="hyperlink pr-3 pb-2">
                            <a><i class="fas fa-download"></i> Generate DO</a>
                        </div>
                        <div class="d-flex pr-3 pb-2" *ngIf="isClosed()">
                            Booking Closed. Changes Not Allowed
                        </div>
                        <div class="d-flex pr-3 pb-2" *ngIf="!isClosed()">
                            <button class="btn btn-primary" (click)="updateGIN(GIN_Task)">
                                Update GIN
                            </button>
                        </div>
                    </div>
                    <!-- Amv6 -->
                    <div class="d-flex flex-row justify-content-center" *ngIf="triggerBookingDelete">
                        <div class="progress">
                            <div class="indeterminate"></div>
                        </div>
                    </div>
                    <!-- Amv6 -end -->
                </div>
                <div *ngIf="Truck_Task != null">
                    Form 4 - Truck Delivery Form
                </div>
                <div class="d-flex flex-column card mb-3">
                    <div class="d-flex flex-row border bottom p-1 card-header">
                        <div class="mr-3"><strong>Task: Close Booking</strong></div><div *ngIf="isProcessed && isClosed()"><i class='fa-solid fa-circle-check text-success'></i></div>
                    </div>
                    <div *ngIf="isProcessed && !isClosed()">
                        <div class="d-flex flex-column p-1">
                            <p>If everything is in order, close the booking.</p> 
                        </div>
                        <div class="d-flex flex-row pl-1 pr-3 pb-2">
                            <div class="pr-3">
                                <button class="btn btn-danger" (click)="closeBooking()">Close Booking</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>