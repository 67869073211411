<div class="position-absolute w-100 vh-100" *ngIf="overlayVisible" style="z-index: 101;">
  <app-item-record-dialog *ngIf="panelVisible(3)"></app-item-record-dialog>
  <!-- Added 25/10/2024 - for Data-> Product Summary Report-> table row onclick dialog -->
  <app-product-summary-data *ngIf="panelVisible(5)"></app-product-summary-data>
</div>
<div class="d-flex flex-fill flex-row overflow-hidden">
  <app-sidebar></app-sidebar>
  <div class="d-flex flex-fill flex-column overflow-hidden">
    <div class="d-flex flex-row">
      <app-toolbar></app-toolbar>
    </div>
    <div class="d-flex flex-fill overflow-hidden">
      <router-outlet></router-outlet>
    </div>
  </div>
  <app-cart-drawer *ngIf="cartVisible"></app-cart-drawer>
</div>