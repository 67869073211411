import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'productSummaryData'
})
export class ProductSummaryDataPipe implements PipeTransform {

  transform(items: any[], locFilterTerm: String, inFilterTerm: String, sortTypeTerm: String): any {

    let locFilterTermUp = locFilterTerm.toUpperCase();
    let inFilterTermUp = inFilterTerm.toUpperCase();

    let sortedItem;

    if(sortTypeTerm === "DSC"){
      sortedItem = (items as any[]).sort((a,b) => {
        return b.Age - a.Age;
      });
    }else{
      sortedItem = (items as any[]).sort((a,b) => {
        return a.Age - b.Age;
      });
    }

    // if(!inFilterTerm){
    //   if(locFilterTerm == "ALL"){
    //     return items;
    //   }else{
    //     return items.filter(function(item){
    //       return (item.filterTerm.includes(locFilterTermUp));
    //     });
    //   }
    // }else{
    //   if(locFilterTerm == "ALL"){
    //     return items.filter(function(item){
    //       return (item.filterTerm.includes(inFilterTermUp));
    //     });
    //   }else{
    //     return items.filter(function(item){
    //       return (item.filterTerm.includes(locFilterTermUp)) && (item.filterTerm.includes(inFilterTermUp));
    //     });
    //   }
    // }

    if(!inFilterTerm){
      if(locFilterTerm == "ALL"){
        return sortedItem;
      }else{
        return sortedItem.filter(function(item){
          return (item.filterTerm.includes(locFilterTermUp));
        });
      }
    }else{
      if(locFilterTerm == "ALL"){
        return sortedItem.filter(function(item){
          return (item.filterTerm.includes(inFilterTermUp));
        });
      }else{
        return sortedItem.filter(function(item){
          return (item.filterTerm.includes(locFilterTermUp)) && (item.filterTerm.includes(inFilterTermUp));
        });
      }
    }
  }

}
