import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OverlayManagerService {

  PANEL_FILTER_DIALOG = 0;
  PANEL_BOOKING_DIALOG = 1;
  PANEL_IMAGE_GALLERY = 2;
  PANEL_ITEM_RECORD_PROFILE = 3;
  PANEL_GRN_ITEM_DIALOG = 4;
  PANEL_DATA_PRODUCT_SUMMARY = 5; //Added 25/10/2024 for Data-> Product Summary Report-> table row onclick dialog

  currentPanel;

  overlayVisible = false;

  //For booking object references
  targetBookingItem;
  imageGallery;
  dataName;
  dataID;
  bookingItem;

  grnItemID;
  productDataSummary; //Added 25/10/2024 to accept productID

  constructor () { }

  showFilterPanel(dataID, dataName) {
    this.dataID = dataID;
    this.dataName = dataName;
    this.currentPanel = this.PANEL_FILTER_DIALOG;
    this.overlayVisible = true;
  }

  showBookingPanel(items) {
    this.currentPanel = this.PANEL_BOOKING_DIALOG;
    this.overlayVisible = true;
  }

  showImageGallery(images) {
    this.imageGallery = images;
    this.currentPanel = this.PANEL_IMAGE_GALLERY;
    this.overlayVisible = true;
  }

  showBookingItemRecord(targetBookingItem) {
    this.bookingItem = targetBookingItem;
    this.overlayVisible = true;
    this.currentPanel = this.PANEL_ITEM_RECORD_PROFILE;
  }

  showGRNItemDialog(grnItemID) {
    this.grnItemID = grnItemID;
    this.overlayVisible = true;
    this.currentPanel = this.PANEL_GRN_ITEM_DIALOG;
  }

  //Added 25/10/2024 for for Data-> Product Summary Report-> table row onclick dialog
  showDataProductSummaryDialog(productDataSummary) {
    this.productDataSummary = productDataSummary;
    this.overlayVisible = true;
    this.currentPanel = this.PANEL_DATA_PRODUCT_SUMMARY;
  }

  panelVisible(panel) {
    return this.currentPanel == panel;
  }

  closePanel() {
    this.targetBookingItem = null;
    this.productDataSummary = null; //Added 25/10/2024 for for Data-> Product Summary Report-> table row onclick dialog
    this.currentPanel = null;
    this.overlayVisible = false;
  }
}
