<div class="overlay-background" (click)="closePanel()">
</div>
<div class="dialog-container">
    <div class="card overflow-hidden">
        <div class="card-header">
            <h6>{{productSummaryData.SKU}} -> {{productSummaryData.Product_Description}}</h6>
        </div>
        <div *ngIf="!availableData">
            <div class="progress">
                <div class="indeterminate"></div>
            </div>
        </div>
        <div class="card-body mb-3" *ngIf="availableData">
            <!-- <p>Filter here</p> -->
             <div class="d-flex flex-row">
                <div class="d-flex flex-fill col-6 pr-3">
                    <input class="form-control" placeholder="Filter Results" [(ngModel)]="filterData">
                </div>
                <div class="d-flex flex-column justify-content-center">
                    <strong>Location:</strong>
                </div>
                <div class="d-flex flex-column pr-3 pl-3 justify-content-center">
                    <select #locationSelect class="form-control" [(ngModel)]="filterLocData">
                        <option *ngFor="let locfilter1 of loc" value="{{locfilter1.value}}">
                            {{locfilter1.value}}
                        </option>
                    </select>
                </div>
             </div>
        </div>
        <div class="card-body overflow-auto table-data">
            <div class="container-fluid" *ngIf="availableData">
                <div class="row">
                    <table class="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th class="text-center">Ref</th>
                                <th class="text-center">S/N</th>
                                <th class="text-center">Avail. Bal</th>
                                <th class="text-center">Total Value</th>
                                <th class="text-center">Storage Location</th>
                                <th class="text-center">GRN Date</th>
                                <th class="text-center clickable" (click)="sortData('age')">
                                    Age(D)
                                    <i class="fas fa-caret-up" *ngIf="sortType == 'ASC'"></i>
                                    <i class="fas fa-caret-down" *ngIf="sortType == 'DSC'"></i>
                                </th>    
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of availableData | productSummaryData: filterLocData:filterData:sortType">
                                <td class="text-center">{{item.Ref}}</td> 
                                <td class="text-center">{{item.Serial_No}}</td> 
                                <td class="text-center">{{item.Balance}}</td> 
                                <td class="text-center">{{item.Dollar_Value}} {{item.Currency}}</td>
                                <td class="text-center">{{item.Bin_Location}}</td>
                                <td class="text-center">{{item.Created_DateTime}}</td>   
                                <td class="text-center">{{item.Age}}</td> 
                            </tr>  
                        </tbody>
                    </table>
                </div>
                
            </div>
        </div>

    </div>
</div>



