import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'adminBookingFilter'
})
export class AdminBookingFilterPipe implements PipeTransform {

  transform(items: any[], filterTerm, completionFilter, bookingFilterType): unknown {

    // if(!items || !filterTerm || filterTerm.length == 0) {
    //   return items;
    // }

    // return items.filter(function(item) {
    //   return item.Ref_No.replaceAll('-', '').toLowerCase().indexOf(filterTerm.toLowerCase()) !== -1;
    // });

    //Amv3
    var filter;
    var filterStr;

    if(!items || !filterTerm || filterTerm.length == 0) {
      filter = items;

      if(completionFilter === "ALL" && bookingFilterType === "ALL"){
        return items;
      }

      if(completionFilter !== "ALL"){
        filter = filter.filter(function(item){
          return item.status == completionFilter;
        });
      }

      if(bookingFilterType !== "ALL"){
        filter = filter.filter(function(item){
          return item.Booking_Type == bookingFilterType;
        });
      }

      return filter;
      
    }else{
      filter = items;

      filterStr = filterTerm.toUpperCase();

      if(completionFilter === "ALL" && bookingFilterType === "ALL"){
        return filter.filter(function(item){
          return item.filterSymantics.includes(filterStr);
        });
      }else{

        filter = filter.filter(function(item){
          return item.filterSymantics.includes(filterStr);
        });

        if(completionFilter !== "ALL"){
          filter = filter.filter(function(item){
            return item.status == completionFilter;
          });
        }

        if(bookingFilterType !== "ALL"){
          filter = filter.filter(function(item){
            return item.Booking_Type == bookingFilterType;
          });
        }

        return filter;

      }

    }
    //Amv3 -end
    
  }
}
